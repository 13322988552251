import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import { translate } from "../../utils/translate";

const Dashboard = (props) => {

    useEffect(()=> {
    },[])

    return (
        <>  
            <div className="row">
            </div>
        </>
   );
};

const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};
 
export default connect(mapStateToProps)(Dashboard);
