import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createCompany, createMasterPolicy, editCompany, editMasterPolicy, getCompanyList, getCompanyPolicyList, getMasterPolicyList, removeCompanyPolicy } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { dataDisplayOptions, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import Swal from "sweetalert2";
import CustomTable from "../../components/CustomTable";
import ListView from "../../components/ListView";

const CompanyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, slug } = useParams();
    const [data, setData] = useState({
        term: "",
        companyName: "",
        logo: "",
        remark: "",
    });
    const inputEl = useRef(null);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        companyName: "",
        term: "",
        logo: "",
        remark: "",
    });
    const [companyPolicyList, setCompanyPolicyList] = useState([]);
    const [companyPolicyListFilter, setCompanyPolicyListFilter] = useState([]);
    const tableColumns = [
        {
            Header: translate(lang, 'index'),
            accessor: (row, index) => {
                return index + 1;
            }
        },
        {
            Header: translate(lang, 'policyName'),
            accessor: (row, index) => {
                return row.policyName;
            }
        },
        {
            Header: translate(lang, 'policyCategory'),
            accessor: (row, index) => {
                return row.term.toUpperCase();
            }
        },
        {
            Header: translate(lang, 'companyName'),
            accessor: (row, index) => {
                return row.companyName;
            }
        },
        {
            Header: translate(lang, 'companyPolicyIcon'),
            accessor: (row, index) => {
                return row.companyName;
            }
        },
        // {
        //     Header: translate(lang, 'action'),
        //     accessor: (row, index) => {
        //         return (<>
        //             <Link
        //                 to={`/company/view/${row.term}`}
        //                 className="btn btn-primary shadow btn-xs sharp me-1"
        //             >
        //                 <i className="fas fa-eye"></i>
        //             </Link>
        //             <Link
        //                 to={`/company/edit/${row.term}`}
        //                 className="btn btn-warning shadow btn-xs sharp me-1"
        //             >
        //                 <i className="fas fa-pen"></i>
        //             </Link>
        //             <Link
        //                 to={`/company/policy/${row.term}`}
        //                 className="btn btn-info shadow btn-xs sharp me-1"
        //             >
        //                 <i className="fas fa-file-alt"></i>
        //             </Link>
        //             <span
        //                 onClick={() => removeItem(row.term)}
        //                 className="btn btn-danger shadow btn-xs sharp"
        //             >
        //                 <i className="fa fa-trash"></i>
        //             </span>
        //         </>)
        //     }
        // },
    ]
    const [fileData, setFileData] = useState({
        name: "company_list",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        if (type === 'create') {
            setData({
                companyName: "",
                displayType: "",
                level: 0,
                icon: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/company');
                return;
            }
            // getDetails(slug);
            // getCompanyPolicies(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/company');
                return;
            }
            // getDetails(slug);
            // getCompanyPolicies(slug);
        }
    }, [])

    const getDetails = async (slug) => {
        let params = { term: slug };
        await getCompanyList(params).then((response) => {
            const result = response.data[0];
            setData({
                id: result.id,
                companyName: result.companyName,
                term: result.term,
                logo: result.logo,
                remark: result.remark,
            })
        }).catch((err) => {
            console.log(err);
            history.push('/company')
        })
    }

    const getCompanyPolicies = async () => {
        let params = { companySlug: slug };
        let res = await getCompanyPolicyList(params);
        if (res.status === 'success') {
            setCompanyPolicyList([...res.data]);
            setCompanyPolicyListFilter([...res.data]);
        } else {
            console.log(res);
        }
    }

    const onFilterInputChange = (input) => {
        // console.log('form', input);
        let temp;
        if (input !== "") {
            temp = companyPolicyList.filter(a => {
                let keys = Object.keys(a);
                let regex = new RegExp(input, "");
                if (keys.find(k => a[k] && a[k].toString().match(regex) && a[k].toString().match(regex).length > 0)) {
                    return a;
                }
                return false;
            })
        } else {
            temp = companyPolicyList;
        }
        setCompanyPolicyListFilter([...temp])
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const onFileChange = (event) => {
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        let error = { companyName: "", term: "", logo: "", remark: "", };
        if (!data.companyName) {
            check = false;
            error.companyName = translate(lang, "errorRequired")
        }
        if (!data.term) {
            check = false;
            error.term = translate(lang, "errorRequired")
        }
        if (!data.remark) {
            check = false;
            error.remark = translate(lang, "errorRequired")
        }
        if (['create'].includes(type)) {
            if (!file || file.length < 1) {
                check = false;
                error.logo = translate(lang, "errorRequired");
            }
        }
        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("companyName", data.companyName);
            formData.append("term", data.term);
            formData.append("remark", data.remark);
            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("logo", file[i], file[i].name);
                }
            }
            if (type === 'create') {
                await createCompany(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/company');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                delete data.id;
                await editCompany(formData, id).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/company');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    function removeItem(id) {
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeCompanyPolicy(id)
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getCompanyPolicies();
                } else {
                    promptMessage(lang, 'error', res.message)
                    getCompanyPolicies();
                }
            }
        })
    }

    const listViewLayout = (list) => {
        return (
            <>
                <div className="d-flex flex-column card shadow-sm company-policy-container-listview">
                    {list.map((i, index) => <>
                        <div className="d-flex flex-column flex-wrap p-3">
                            <div className="d-flex flex-column flex-md-row flex-wrap">
                                <div className="col-12 col-md-6"><b>{translate(lang, 'policyName')}</b>: {i.policyName}</div>
                                <div className="col-12 col-md-6"><b>{translate(lang, 'policyCategory')}</b>: {i.policyName}</div>
                                <div className="col-12 col-md-6"><b>{translate(lang, 'companyName')}</b>: {i.policyName.name}</div>
                                <div className="col-12 col-md-6"><b>{translate(lang, 'rider')}</b>: {i.policyName}</div>
                            </div>
                        </div>
                    </>)}
                </div>
            </>
        )
    }

    return (
        <>
            <div className="d-flex flex-column">
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "detailsCompany" : type === "edit" ? "editCompany" : "createCompany"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column flex-md-row gap-3">
                            <div className="d-flex flex-column col-12 col-md-6">
                                <div className="form-group py-2 px-2 col-12">
                                    <label className="form-label required">{translate(lang, 'name')}</label>
                                    <input type="text" name="companyName" value={data.companyName} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.companyName !== "" ? <><small className="text-danger">{error.companyName}</small></> : <></>}
                                </div>
                                <div className="form-group py-2 px-2 col-12">
                                    <label className="form-label required">{translate(lang, 'code')}</label>
                                    <input type="text" name="term" value={data.term} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.term !== "" ? <><small className="text-danger">{error.term}</small></> : <></>}
                                </div>
                                <div className="form-group py-2 px-2 col-12">
                                    <label className="form-label">{translate(lang, 'remark')}</label>
                                    <input type="text" name="remark" value={data.remark} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    {error.remark !== "" ? <><small className="text-danger">{error.remark}</small></> : <></>}
                                </div>
                                {/* <div className="form-group py-2">
                                <label className="form-label">{translate(lang, 'level')}</label>
                                <input type="number" min="0" step="1" value={data.level} onChange={(e) => { setData({ ...data, level: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            </div> */}
                                <div className="py-2 px-2 col-12">
                                    <div hidden={type === 'view' ? true : false}>
                                        <label className="form-label required">{translate(lang, 'companyLogo')}</label>
                                        <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e)} ref={inputEl} />
                                        {error.logo !== "" ? <><small className="text-danger">{error.logo}</small></> : <></>}
                                    </div>
                                    {type !== 'create' ? (<div className="mt-2 d-flex flex-column">
                                        <label className="form-label required">{translate(lang, 'currentCompanLogo')}</label>
                                        <img src={`${api.companyLogo}/${data.logo}`} alt="logo" className="img-thumbnail icon-box" />
                                        {/* <div className={`icon-box`}>
                                        </div> */}
                                    </div>) : <></>}
                                </div>
                            </div>
                            {/* <div className="d-flex flex-column col-12 col-md-6 company-policy-listview">
                                <div className="px-3 d-flex flex-row mb-3">
                                    <span className="h3 my-auto">{translate(lang, "companyPolicyList")}</span>
                                </div>
                                <div className="px-3 h-25-vh">
                                    {companyPolicyList.length > 0 ?
                                        <ListView data={companyPolicyListFilter} onFilterInputChange={(input) => onFilterInputChange(input)} layout={listViewLayout(companyPolicyListFilter)} search={true} lang={props.lang}></ListView>
                                        : <p>{translate(lang, "noPolicyFoundOnThisUser")}</p>
                                    }
                                </div>
                            </div> */}
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-sm btn-primary" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-sm btn-dark mx-2" onClick={() => history.push('/company')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CompanyForm);