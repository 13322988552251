import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserList, removeCustomer } from "../../../services/ApiService";
import { promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";
import { dummyData } from "../../../utils/functions";

const MemberList = (props) => {
    const lang = props.lang;
    const tableColumns = [
        {
            Header: translate(lang, 'index'),
            accessor: (row, index) => {
                return index + 1;
            }
        },
        {
            Header: translate(lang, 'fullName'),
            accessor: (row, index) => {
                return row.fullName ?? "";
            }
        },
        {
            Header: translate(lang, 'agent'),
            accessor: (row, index) => {
                return row.agentInCharge?.fullName;
            }
        },
        {
            Header: translate(lang, 'phone'),
            accessor: (row, index) => {
                return row.phoneCode + " " + row.phone;
            },
            Cell: (props) => {
                return <><span className="text-nowrap">{props.cell.value}</span></>
            }
        },
        {
            Header: translate(lang, 'email'),
            accessor: (row, index) => {
                return row.email
            }
        },
        {
            Header: translate(lang, 'gender'),
            accessor: (row, index) => {
                return row.gender && row.gender !== "null" ? row.gender : "";
            },
            Cell: (props) => {
                return (<><span className="text-capitalize">{props.cell.value}</span></>)
            }
        },
        {
            Header: translate(lang, 'status'),
            accessor: (row, index) => {
                return row.status;
            },
            Cell: ({ value }) => (
                <span className="text-primary">{value}</span>
            )
        },
        {
            Header: translate(lang, 'action'),
            accessor: (row, index) => {
                return (<>
                    <Link
                        to={`/member/view/${row.id}`}
                        className="btn btn-info shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-eye"></i>
                    </Link>
                    <Link
                        to={`/member/edit/${row.id}`}
                        className="btn btn-warning shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-edit"></i>
                    </Link>
                    <Link
                        to={`/member/policy/create/${row.id}`}
                        className="btn btn-secondary shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-plus"></i>
                    </Link>
                    <Link
                        to={`/member/policy/edit/${row.id}`}
                        className="btn btn-success shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-file"></i>
                    </Link>
                    <span
                        onClick={() => removeItem(row.id)}
                        className="btn btn-danger shadow btn-xs sharp"
                    >
                        <i className="fa fa-trash"></i>
                    </span>
                </>)
            }
        },
    ]
    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "member_list_report",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        let query = { isAgent: 0 };
        setData([...dummyData('customer')]);
        setFileData([...dummyData('customer')]);
        // let res = await getUserList(query);
        // if (res?.status === 'success') {
        //     setData([...res.data])
        //     setFileData([...res.data])
        // } else {
        // }
    }

    function removeItem(id) {
        // Swal.fire({
        //     title: translate(props.lang, "deleteConfirmation"),
        //     text: translate(props.lang, "deleteMessage"),
        //     showDenyButton: true,
        //     showCancelButton: false,
        //     confirmButtonText: 'Yes',
        //     denyButtonText: 'No',
        //     customClass: {
        //         actions: 'my-actions',
        //         cancelButton: 'order-1 right-gap',
        //         confirmButton: 'order-2',
        //         denyButton: 'order-3',
        //     }
        // }).then(async (result) => {
        //     if (result.isConfirmed) {
        //         let res = await removeCustomer(id)
        //         if (res.status === 'success') {
        //             promptMessage(lang, 'success', res.message)
        //             getList();
        //         } else {
        //             promptMessage(lang, 'error', res.message)
        //             getList();
        //         }
        //     }
        // })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <h3>{translate(lang, 'member')}</h3>
                    </div>
                    <div>
                        <Link to={`/member/create`} className="btn btn-sm btn-primary" >{translate(lang, 'createMember')}</Link>
                        {/* <button className="btn btn-sm btn-primary" onClick={() => createMember()}>{translate(lang, 'createMember')}</button> */}
                    </div>
                </div>
                <div className="card-body">
                    <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={true}
                            fileData={fileData}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MemberList);