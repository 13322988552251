import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createPolicyCategory, editPolicyCategory, getMasterPolicyList, getPolicyCategoryList } from "../../../services/ApiService";
import { dataDisplayOptions, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { api } from "../../../utils/api";
import ColorPicker from 'react-color-picker';

import 'react-color-picker/index.css';

const PolicyCategoryForm = (props) => {
    const lang = props.lang;
    const [data, setData] = useState({
        name: "",
        master: "",
        description: "",
        level: 0,
        icon: "",
        color: "",
        secondaryColor: "",
        type: "",
        riderGroupName: "",
        riderGroupIcon: "",
    });
    const [masterListOptions, setMasterListOptions] = useState([]);
    const history = useHistory();
    // const displayTypeOptions = dataDisplayOptions(props.lang);
    const { type, slug } = useParams();
    const inputEl = useRef(null);
    const inputE2 = useRef(null);
    const [file, setFile] = useState([]);
    const [riderGroupIcon, setRiderGroupIcon] = useState([]);
    const [error, setError] = useState({
        name: "",
        master: "",
        description: "",
        level: "",
        icon: "",
        color: "",
        secondaryColor: "",
        type: "",
        riderGroupName: "",
        riderGroupIcon: "",
    });
    useEffect(() => {
        getMasterList();
        if (type === 'create') {
            setData({
                name: "",
                master: "",
                description: "",
                level: 0,
                icon: "",
                color: "#000000",
                secondaryColor: "#cfcfcf",
                type: "",
                riderGroupName: "",
                riderGroupIcon: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/settings/policy-category');
                return;
            }
            getDetails(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/settings/policy-category');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const getMasterList = async () => {
        let res = await getMasterPolicyList();
        if (res.status === 'success') {
            let temp = res.data.map((item) => ({ label: item.name, value: item.id }))
            setMasterListOptions([...temp])
        }
    }

    const getDetails = async (slug) => {
        let params = { slug: slug };
        await getPolicyCategoryList(params).then((response) => {
            const result = response.data[0];
            setData({
                id: result.id,
                name: result.name,
                master: result.policy_master.id,
                description: result.description ?? "",
                level: result.ringLevel,
                icon: result.icon,
                color: result.color,
                secondaryColor: result.secondaryColor,
                type: result.type,
                riderGroupName: result.riderGroupName,
                riderGroupIcon: result.riderGroupIcon,
            })
        }).catch((err) => {
            console.log(err);
            history.push('/settings/policy-category')
        })
    }

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const onFileChange = (event, name) => {
        if (name === "policyCategoryIcon") setFile(event.target.files);
        if (name === "riderGroupIcon") setRiderGroupIcon(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        let error = { name: "", master: "", type: "", level: "", description: "", color: "", file: "", };
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.master) {
            check = false;
            error.master = translate(lang, "errorRequired")
        }
        if (!data.type) {
            check = false;
            error.type = translate(lang, "errorRequired")
        }
        if (data < 0) {
            check = false;
            error.level = translate(lang, "errorRequired")
        }
        setError({ ...error });
        if (check) {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("type", data.type);
            formData.append("master", data.master);
            formData.append("description", data.description ?? "");
            formData.append("ringLevel", data.level);
            formData.append("color", data.color);
            formData.append("secondaryColor", data.secondaryColor);
            formData.append("riderGroupName", data.riderGroupName);
            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append("attachments", file[i], file[i].name);
                }
            }
            if (riderGroupIcon && riderGroupIcon.length > 0) {
                for (let i = 0; i < riderGroupIcon.length; i++) {
                    formData.append("riderGroupIcon", riderGroupIcon[i], riderGroupIcon[i].name);
                }
            }
            if (type === 'create') {
                delete data.id;
                await createPolicyCategory(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/policy-category');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                delete data.id;
                await editPolicyCategory(formData, id).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/policy-category');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    return (
        <>
            <div className="card shadow col-12 col-md-8">
                <div className="card-header">
                    <h3 className="m-0">
                        {translate(lang, (type === "view" ? "detailsPolicyCategory" : type === "edit" ? "editPolicyCategory" : "createPolicyCategory"))}
                    </h3>
                </div>
                <div className="card-body col-12 d-flex flex-column flex-wrap">
                    <div className="d-flex flex-column flex-md-row flex-wrap ">
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            <label className="form-label required">{translate(lang, 'name')}</label>
                            <input type="text" name="name" value={data.name} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.name}</label>
                        </div>
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            <label className="form-label required">{translate(lang, 'code')}</label>
                            <input type="text" name="type" value={data.type} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.type}</label>
                        </div>
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            <label className="form-label required">{translate(lang, 'level')}</label>
                            <input type="number" min="0" step="1" name="level" value={data.level} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.level}</label>
                        </div>
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            <label className="form-label required">{translate(lang, 'masterPolicy')}</label>
                            <Select
                                className="form-control"
                                value={masterListOptions.filter(
                                    (option) => option.value === data.master
                                )}
                                isDisabled={type === 'view' ? true : false}
                                onChange={(e) => handleOptionSelected(e, "master")}
                                options={masterListOptions}
                                placeholder={translate(lang, "placeholderChoose")}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        height: "100%",
                                        border: 0,
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                        "&:hover": {
                                            borderColor: "transparent",
                                            boxShadow: "none",
                                        },
                                        backgroundColor: "transparent",
                                    }),
                                    valueContainer: (base, state) => ({
                                        ...base,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        borderColor: "transparent",
                                    })
                                }}
                            />
                            <label className="error">{error.master}</label>
                        </div>
                        <div className="form-group py-2 col-12 px-2">
                            <label className="form-label">{translate(lang, 'description')}</label>
                            <input type="text" name="description" value={data.description} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.description}</label>
                        </div>
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            <label className="form-label">{translate(lang, 'riderGroupName')}</label>
                            <input type="text" name="riderGroupName" value={data.riderGroupName} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.riderGroupName}</label>
                        </div>
                        <div className="form-group py-2 col-12 col-md-6 px-2">
                            {/* <label className="form-label">{translate(lang, 'riderGroupIcon')}</label> */}
                            {/* <input type="text" name="description" value={data.riderGroupIcon} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                            <div hidden={type === 'view' ? true : false}>
                                <label className="form-label">{translate(lang, 'riderGroupIcon')}</label>
                                <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e, "riderGroupIcon")} ref={inputE2} />
                                <label className="error">{error.icon}</label>
                            </div>
                            {type !== 'create' ? (<div className="mt-2">
                                <label className="form-label">{translate(lang, 'currentRiderGroupIcon')}</label>
                                <div className={`icon-box`}>
                                    {data.icon ? <img src={`${api.policyRiderIcon}/${data.riderGroupIcon}`} alt="logo" className="w-100 h-100" /> : <></>}
                                </div>
                            </div>) : <></>}
                        </div>
                        <div className="form-group py-2 col-12 d-flex flex-column flex-md-row px-2">
                            <div className="col-4 d-flex flex-row flex-md-column">
                                <div className="">
                                    <label className="form-label">{translate(lang, 'color')}</label>
                                    {/* <ColorPicker value={data.color} onChange={(e) => { setData({ ...data, color: e.target.value })}} /> */}
                                    <input type="color" name="color" value={data.color} onChange={(e) => onInputChange(e)} className="form-control color-box-input" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="">
                                    <label className="form-label">{translate(lang, 'secondaryColor')}</label>
                                    {/* <ColorPicker value={data.color} onChange={(e) => { setData({ ...data, color: e.target.value })}} /> */}
                                    <input type="color" name="secondaryColor" value={data.secondaryColor} onChange={(e) => onInputChange(e)} className="form-control color-box-input" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                            </div>
                            <div className="col-8">
                                <div hidden={type === 'view' ? true : false}>
                                    <label className="form-label">{translate(lang, 'icon')}</label>
                                    <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e, "policyCategoryIcon")} ref={inputEl} />
                                    <label className="error">{error.icon}</label>
                                </div>
                                {type !== 'create' ? (<div className="mt-2">
                                    <label className="form-label">{translate(lang, 'currentIcon')}</label>
                                    <div className={`icon-box`}>
                                        {data.icon ? <img src={`${api.policyCategoryIcon}/${data.icon}`} alt="logo" className="w-100 h-100" /> : <></>}
                                    </div>
                                </div>) : <></>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="d-flex flex-row">
                        <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                        <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/policy-category')}>{translate(lang, "back")}</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(PolicyCategoryForm);