import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
} from '../../services/AuthService';
import { promptMessage } from '../../utils/functions';
import { translate } from '../../utils/translate';
import { dummyData } from '../../utils/functions';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_LOCAL_STORAGE_DETAILS = 'update local storage data';

export function logout(history) {
    localStorage.removeItem('ia-admin');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, history, lang) {
    return (dispatch) => {
        let data = {...dummyData('admin')[0]}
        saveTokenInLocalStorage(data);
        dispatch(loginConfirmedAction(data));
        promptMessage(lang, 'success', 'success');
        history.push('/dashboard');
        // login(username, password)
        //     .then((response) => {
        //         if (response.data.status === 'success') {
        //             saveTokenInLocalStorage(response.data);
        //             runLogoutTimer(
        //                 dispatch,
        //                 600 * 1000,
        //                 history,
        //             );
        //             dispatch(loginConfirmedAction(response.data));
        //             promptMessage(lang, 'success', response.data.message);
        //             history.push('/dashboard');                
        //         } else {
        //             promptMessage(lang, 'error', response.data.message);
        //         }
        //     })
        //     .catch((error) => {
        //         let err = error.response ? error.response.data : {message: translate(lang, "internalServerError")};
        // 		// console.log(error.response);
        //         promptMessage(lang, 'error', err.message);
        //         // const errorMessage = formatError(error.response.data);
        //         dispatch(loginFailedAction(err.message));
        //     });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
