import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import Logo from "../../../images/logo-black.png";
import LogoSmall from "../../../images/favicon.png";

const NavHader = () => {
	const logoSize = { w: 50, h: 50 };
	const [toggle, setToggle] = useState(false);
	const { navigationHader, openMenuToggle, background } = useContext(
		ThemeContext
	);
	return (
		<div className="nav-header">
			<Link to="/dashboard" className="brand-logo">
				{background.value === "dark" || navigationHader !== "color_1" ? (
					<>
						<img src={LogoSmall} className="img-fluid p-3 nav-header-logo-mobile" style={{}} />
						<img src={Logo} className="img-fluid p-3 rounded-3 nav-header-logo" style={{ width: "70%" }} />
					</>
				) : (
					<>
						<img src={LogoSmall} className="img-fluid p-3 nav-header-logo-mobile" style={{}} />
						<img src={Logo} className="img-fluid p-3 rounded-3 nav-header-logo" style={{ width: "70%" }} />
					</>
				)}
			</Link>

			<div
				className="nav-control"
				onClick={() => {
					//   setToggle(!toggle);
					//   openMenuToggle();
				}}
			>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;
