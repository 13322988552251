import Swal from "sweetalert2";
import { translate } from "./translate";

export const promptMessage = (lang, type, msg, optional = {}) => {
    let basic = {
        showConfirmButton: true,
        timer: 2500,
    }
    basic = { ...basic, ...optional }
    if (optional.timer === 0) {
        delete basic.timer;
    }

    return Swal.fire({
        icon: type,
        title: translate(lang, type),
        text: lang === "EN" ? msg.toUpperCase() : msg,
        ...basic
    });
}

export const dataDisplayOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'ring'), value: "ring", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'barChart'), value: "barChart", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const genderOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'male'), value: "male", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'female'), value: "female", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const relationshipOptions = (lang) => {
    let options = [
        { id: 1, label: translate(lang, 'father'), value: "father", isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'mother'), value: "mother", isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'guardian'), value: "guardian", isSelected: false, isHidden: false, },
        { id: 4, label: translate(lang, 'child'), value: "child", isSelected: false, isHidden: false, },
    ]
    return options.filter(i => i.isHidden === false);
}

export const questionairType = (lang) => {
    let types = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'chooseOne', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseMultiple'), value: 'chooseMulti', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'fillIn'), value: 'fillIn', isSelected: false, isHidden: false, },
    ]
    return types.filter(t => t.isHidden === false);
}

export const languageOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'chooseOne'), value: 'en', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'chooseOne'), value: 'cn', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'chooseOne'), value: 'bm', isSelected: false, isHidden: false, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const referenceOptions = (lang) => {
    let langs = [
        { id: 1, label: translate(lang, 'url'), value: 'url', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'image'), value: 'image', isSelected: false, isHidden: true, },
    ]
    return langs.filter(l => l.isHidden === false);
}

export const employmentOptions = (lang) => {
    let opt = [
        { id: 1, label: translate(lang, 'employed'), value: 'employed', isSelected: false, isHidden: false, },
        { id: 2, label: translate(lang, 'nonEmployed'), value: 'non_employed', isSelected: false, isHidden: false, },
        { id: 3, label: translate(lang, 'retiree'), value: 'retiree', isSelected: false, isHidden: false, },
    ]
    return opt.filter(l => l.isHidden === false);
}

export const numberWithFormat = (number, localStringFormat = "en-US") => {
    if (typeof number === "number") {
        return number.toLocaleString(localStringFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        if (isNaN(parseFloat(number)) && isNaN(parseInt(number))) {
            return "Invalid number";
        } else {
            return parseFloat(number).toLocaleString(localStringFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    }
}

export const checkStringFormat = (string, format) => {
    if (string.match(format)) {
        return true
    }
    return false;
}

export const selectDropdownStyle = () => {
    return {
        control: (base, state) => ({
            ...base,
            height: "100%",
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
            "&:hover": {
                borderColor: "transparent",
                boxShadow: "none",
            },
            backgroundColor: "transparent",
        }),
        valueContainer: (base, state) => ({
            ...base,
            paddingLeft: 0,
            paddingRight: 0,
            borderColor: "transparent",
        })
    }
}

export const dummyData = (type) => {
    let all = {
        admin: [
            { "id": 2, "email": "admin@gmail.com", "username": "admin", "fullName": "admin", "role": "admin", "phoneCode": "60", "phone": null, "isMaster": 0, "status": "active", "createdBy": "root@root.root", "updatedBy": null, "createdAt": "2023-03-14T03:57:13.000Z", "updatedAt": "2023-03-14T03:57:13.000Z", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwicGhvbmVDb2RlIjoiNjAiLCJwaG9uZSI6bnVsbCwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MDE5MTQ5NjIsImV4cCI6MTcwMzEyNDU2Mn0.oYkaqVHvFr6HKkFdTJti7QBgXoNGMSjLN2GrKdWDJSA" },
        ],
        customer: [
            { "id": 88, "email": "qwe@123.com", "username": null, "pinKey": "123123", "fullName": "tqtqt", "gender": "male", "age": 0, "identityCard": "tqwe1234", "address": "", "birthDate": "3242-02-23", "occupationId": null, "annualIncome": 123123, "monthlyCommitment": 123123, "retireeIncomeMonthly": 123123, "coverageYear": 10, "employmentStatus": "employed", "phoneCode": "+60", "phone": "2345241231245", "profileImage": "", "forgetPasswordToken": null, "isAgent": 0, "company": null, "companyAgency": null, "companyMobilePhone": null, "companyEmail": null, "agentCode": null, "agentLIAMNumber": null, "yearOfExperience": null, "assignedAgent": 42, "status": "active", "createdBy": "2345241231245", "updatedBy": null, "createdAt": "2023-11-08T05:21:23.000Z", "updatedAt": "2023-11-08T05:21:23.000Z", "agentInCharge": { "fullName": "123123123c", "id": 42 } },
            { "id": 87, "email": "123@123.com", "username": null, "pinKey": "123123", "fullName": "qweqwe", "gender": "male", "age": 0, "identityCard": "123123123", "address": "", "birthDate": "2342-12-12", "occupationId": null, "annualIncome": 123123, "monthlyCommitment": 123123, "retireeIncomeMonthly": 123123, "coverageYear": 10, "employmentStatus": "employed", "phoneCode": "+60", "phone": "12324", "profileImage": "", "forgetPasswordToken": null, "isAgent": 0, "company": null, "companyAgency": null, "companyMobilePhone": null, "companyEmail": null, "agentCode": null, "agentLIAMNumber": null, "yearOfExperience": null, "assignedAgent": 41, "status": "active", "createdBy": "12324", "updatedBy": null, "createdAt": "2023-11-08T05:20:44.000Z", "updatedAt": "2023-11-08T05:20:44.000Z", "agentInCharge": { "fullName": "qweqwewq", "id": 41 } },
        ],
        agent: [
            { "id": 45, "email": "agent6@agent.com", "username": "", "pinKey": "123123", "fullName": "agent5", "gender": null, "age": null, "identityCard": null, "address": null, "birthDate": null, "occupationId": null, "annualIncome": 0, "monthlyCommitment": 0, "retireeIncomeMonthly": 0, "coverageYear": 10, "employmentStatus": "employed", "phoneCode": "+60", "phone": "11332244", "profileImage": null, "forgetPasswordToken": null, "isAgent": 1, "company": null, "companyAgency": null, "companyMobilePhone": null, "companyEmail": null, "agentCode": null, "agentLIAMNumber": null, "yearOfExperience": null, "assignedAgent": null, "status": "active", "createdBy": "2", "updatedBy": null, "createdAt": "2023-10-17T02:31:55.000Z", "updatedAt": "2023-10-17T02:31:55.000Z" },
            { "id": 42, "email": "qwe@1111.com", "username": "", "pinKey": "123123", "fullName": "123123123c", "gender": "", "age": null, "identityCard": null, "address": null, "birthDate": null, "occupationId": null, "annualIncome": 0, "monthlyCommitment": 0, "retireeIncomeMonthly": 0, "coverageYear": 10, "employmentStatus": "employed", "phoneCode": "+370", "phone": "7889778897", "profileImage": null, "forgetPasswordToken": null, "isAgent": 1, "company": null, "companyAgency": null, "companyMobilePhone": null, "companyEmail": null, "agentCode": null, "agentLIAMNumber": null, "yearOfExperience": null, "assignedAgent": null, "status": "active", "createdBy": "2", "updatedBy": "2", "createdAt": "2023-10-12T21:09:57.000Z", "updatedAt": "2023-10-12T21:10:16.000Z" },
        ],
        company: [
            { "id": 2, "companyName": "AIAA Group Limited", "term": "aiaa", "logo": "aiaa.png", "remark": null, "status": "active", "createdBy": "root@root.root", "updatedBy": null, "createdAt": "2023-03-14T03:57:13.000Z", "updatedAt": "2023-03-14T03:57:13.000Z" },
            { "id": 1, "companyName": "Ellianz Malaysia", "term": "ellianz", "logo": "ellianz.png", "remark": null, "status": "active", "createdBy": "root@root.root", "updatedBy": null, "createdAt": "2023-03-14T03:57:13.000Z", "updatedAt": "2023-03-14T03:57:13.000Z" },
        ]
    }
    return all[type]
} 