import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch, useSelector } from 'react-redux';
import {  Route, Switch, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";
import "./css/custom.css";
import { basicAxiosInstance, noTokenAxiosInstance } from './services/AxiosInstance';


const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});

function App (props) {
    const dispatch = useDispatch();
    noTokenAxiosInstance.defaults.headers.common['lang'] = props.lang;
    basicAxiosInstance.defaults.headers.common['lang'] = props.lang;
    basicAxiosInstance.defaults.headers.common['authorization'] = `Bearer ${props.token}`;
    // noTokenAxiosInstance.interceptors.request.use((config) => {
    //     config.headers['lang'] = props.lang;
    //     return config;
    // });
    // basicAxiosInstance.interceptors.request.use((config) => {
    //     config.headers['authorization'] = 'Bearer ' + props.token;
    //     config.headers['lang'] = props.lang;
    //     return config;  
    // });
    useEffect(() => {
        switch(window.location.pathname) {
            case '/page-register': case '/page-forgot-password':
                break;
            default: {
                checkAutoLogin(dispatch, props.history);
            }
        }
        if(localStorage.getItem('ia-admin-lang')){
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: localStorage.getItem('ia-admin-lang'),
            });
        } else {
            localStorage.setItem('ia-admin-lang', 'EN');
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: "EN",
            });
        }
        // checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index/>
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        lang: state.i18n.lang,
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 

