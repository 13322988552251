import { basicAxiosInstance, noTokenAxiosInstance } from '../services/AxiosInstance';

export async function getUserList(params) {
    return basicAxiosInstance.get(`/users`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createUser(body) {
    return basicAxiosInstance.post(`/users/`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createAgent(body) {
    return basicAxiosInstance.post(`/users/agent`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createAdmin(body) {
    return basicAxiosInstance.post(`/users/admin`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function editAgent(id, body) {
    return basicAxiosInstance.put(`/users/${id}/agent`, {
        ...body,
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function removeAgent(id) {
    return basicAxiosInstance.put(`/users/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getMasterPolicyList(params) {
    return basicAxiosInstance.get(`/policy/master`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function getPolicyCategoryList(params) {
    return basicAxiosInstance.get(`/policy/category`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMasterPolicy(body) {
    return basicAxiosInstance.post('/policy/master', body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editMasterPolicy(body, id) {
    return basicAxiosInstance.put(`/policy/master/${id}`, body,{
        headers: {"Content-Type": "multipart/form-data;"},
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeMasterPolicy(id) {
    return basicAxiosInstance.put(`/policy/master/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createPolicyCategory(body) {
    return basicAxiosInstance.post('/policy/category', body, {
        headers: {"Content-Type": "multipart/form-data;"},
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editPolicyCategory(body, id) {
    return basicAxiosInstance.put(`/policy/category/${id}`, body, {
        headers: {"Content-Type": "multipart/form-data;"},
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removePolicyCategory(id) {
    return basicAxiosInstance.put(`/policy/category/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getCompanyList(params) {
    return noTokenAxiosInstance.get(`/company`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getUserPolicyList(params) {
    return basicAxiosInstance.get(`/policy`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function getRiderList(params) {
    return basicAxiosInstance.get(`/policy/riders`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createRider(body) {
    return basicAxiosInstance.post('/policy/riders', body,  {
        headers: {"Content-Type": "multipart/form-data;"},
    })
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editRider(body, id) {
    return basicAxiosInstance.put(`/policy/riders/${id}`, body,  {
        headers: {"Content-Type": "multipart/form-data;"},
    })
    .then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeRider(id) {
    return basicAxiosInstance.put(`/policy/riders/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function createCustomer(body) {
    return basicAxiosInstance.post('/users/customer', body, {
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // }
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editCustomer(id, body) {
    return basicAxiosInstance.put(`/users/customer/${id}`, body, {
        // headers: {
        //     'Content-Type': 'multipart/form-data',
        // }
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeCustomer(id) {
    return basicAxiosInstance.put(`/users/customer/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    });
}

export async function createMemberPolicy(body) {
    return basicAxiosInstance.post(`/policy/`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function editMemberPolicy(body, id) {
    return basicAxiosInstance.put(`/policy/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response ?? err;
    })
}

export async function removeMemberPolicy(id) {
    return basicAxiosInstance.put(`/policy/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createCompany(body) {
    return basicAxiosInstance.post(`/company/`, body, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editCompany(body, id) {
    return basicAxiosInstance.put(`/company/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeCompany(id) {
    return basicAxiosInstance.put(`/company/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function getCompanyPolicyList(params) {
    return basicAxiosInstance.get(`/company/policy`, {
        params: params
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function createCompanyPolicy(body) {
    return basicAxiosInstance.post(`/company/policy`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function editCompanyPolicy(body, id) {
    return basicAxiosInstance.put(`/company/policy/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function removeCompanyPolicy(id) {
    return basicAxiosInstance.put(`/company/policy/${id}/remove`).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}

export async function updatePolicyQuestionnaire(body, id) {
    return basicAxiosInstance.put(`/policy/questionnaire/${id}`, body).then((response) => {
        return response.data;
    }).catch((err) => {
        return err.response.data ?? err;
    })
}
