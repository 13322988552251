import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createPolicyCategory, createRider, editPolicyCategory, editRider, getMasterPolicyList, getPolicyCategoryList, getRiderList } from "../../../services/ApiService";
import { dataDisplayOptions, promptMessage, referenceOptions } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { api } from "../../../utils/api";
import ColorPicker from 'react-color-picker';

const RiderForm = (props) => {
    const lang = props.lang;
    const [data, setData] = useState({
        id: null,
        name: null,
        key: null,
        description: null,
        references: [
            { url: "", type: "", remark: "" }
        ],
        assignedCategory: null,
    });
    const [referenceOption, setReferenceOption] = useState(referenceOptions);
    const [policyCategoryOptions, setPolicyCategoryOptions] = useState([]);
    const history = useHistory();
    // const displayTypeOptions = dataDisplayOptions(props.lang);
    const { type, slug } = useParams();
    const inputEl = useRef(null);
    const [error, setError] = useState({
        name: "",
        key: "",
        assignedCategory: "",
    });
    useEffect(() => {
        loadBasic();
        if (type === 'create') {
            setData({
                name: "",
                key: "",
                slug: "",
                description: "",
                references: [],
                assignedCategory: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/settings/rider');
                return;
            }
            getDetails(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/settings/rider');
                return;
            }
            getDetails(slug);
        }
    }, [])

    const loadBasic = async () => {
        let res = await getPolicyCategoryList();
        if (res.status === 'success') {
            let temp = res.data.map((item) => ({ label: item.name, value: item.id, slug: item.slug, }))
            setPolicyCategoryOptions([...temp])
        }
    }

    const getDetails = async (slug) => {
        let params = { slug: slug };
        await getRiderList(params).then((response) => {
            const result = response.data[0];
            const ref = response.data[0].referenceURL;
            setData({
                id: result.id,
                name: result.name,
                key: result.key,
                assignedCategory: result.policy_riders.map(r => ({ label: r.policy_category.name, value: r.policy_category.id, slug: r.policy_category.slug, })),
                description: result.description,
                references: ref ? JSON.parse(ref) : [],
            })
        }).catch((err) => {
            console.log(err);
            history.push('/settings/rider')
        })
    }

    const handleOptionSelected = (e, name, i) => {
        const list = { ...data };
        if (name === 'referenceType') {
            console.log(name, e);
            list.references[i].type = e.value;
        } else {
            list[name] = e;
        }
        setData({ ...list });
    }

    const onInputChange = (event, i = null) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        if (i !== null) {
            temp.references[i][name] = value;
        } else {
            temp[name] = value;
        }
        setData({ ...temp });
    };

    const addNewReference = () => {
        let temp = data;
        console.log(data)
        temp.references.push({ url: "", type: referenceOption.find(opt => opt.value === "url").value, remark: "" });
        setData({ ...temp });
    };

    const onFileChange = (event, index) => {
        let temp = data;
        temp.references[index].url = event.target.files;
        setData({ ...temp });
    };


    const removeReference = (i) => {
        let temp = data;
        temp.references.splice(i, 1);
        setData({ ...temp });
    };

    const submitForm = async () => {
        let check = true;
        let error = { name: "", assignedCategory: "", key: "" };
        if (!data.name) {
            check = false;
            error.name = translate(lang, "errorRequired")
        }
        if (!data.key) {
            check = false;
            error.key = translate(lang, "errorRequired")
        }
        if (!data.assignedCategory || data.assignedCategory.length < 1) {
            check = false;
            error.assignedCategory = translate(lang, "errorRequired")
        }
        setError({ ...error });
        if (check) {
            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("key", data.key);
            formData.append("description", data.description ?? "");
            formData.append("references", JSON.stringify(data.references));
            formData.append("assignedCategory", JSON.stringify(data.assignedCategory));
            data.references.forEach(ref => {
                if (ref.type === 'url') {
                    return ref;
                } else {
                    if (ref.url && ref.url.length > 0) {
                        for (let i = 0; i < ref.url.length; i++) {
                            formData.append("attachments", ref.url[i], ref.url[i].name);
                        }
                    }
                }
            })
            if (type === 'create') {
                delete data.id;
                await createRider(formData).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/rider');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            } else if (type === 'edit') {
                let id = data.id;
                formData.append("id", data.id);
                delete data.id;
                delete data.key;
                await editRider(formData, id).then((res) => {
                    if (res.status === 'success') {
                        promptMessage(lang, 'success', res.message)
                        history.push('/settings/rider');
                    } else {
                        promptMessage(lang, 'error', res.message)
                    }
                }).catch((error) => {
                    promptMessage(lang, 'error', error);
                })
            }
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="card shadow col-12 col-md-6">
                    <div className="card-header">
                        <h3 className="m-0">
                            {translate(lang, (type === "view" ? "detailsRider" : type === "edit" ? "editRider" : "createRider"))}
                        </h3>
                    </div>
                    <div className="card-body col-12 d-flex flex-column flex-wrap">
                        <div className="form-group py-2">
                            <label className="form-label required">{translate(lang, 'name')}</label>
                            <input type="text" name="name" value={data.name} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.name !== "" ? <small className="text-danger">{error.name}</small> : <></>}
                        </div>
                        <div className="form-group py-2">
                            <label className="form-label required">{translate(lang, 'code')}</label>
                            <input type="text" name="key" value={data.key} onChange={(e) => onInputChange(e)} className="form-control" disabled={type !== 'create' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            {error.key !== "" ? <small className="text-danger">{error.key}</small> : <></>}
                        </div>
                        <div className="form-group py-2">
                            <label className="form-label">{translate(lang, 'description')}</label>
                            <input type="text" name="description" value={data.description} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                            <label className="error">{error.description}</label>
                        </div>
                        <div className="form-group py-2 h-100">
                            <label className="form-label required">{translate(lang, 'assignedPolicyCategory')}</label>
                            {/* <input type="number" min="0" step="1" name="level" value={data.level} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                            {/* <label className="error">{error.level}</label> */}
                            <Select
                                isMulti
                                className="form-control h-100"
                                value={data.assignedCategory}
                                isDisabled={type === 'view' ? true : false}
                                onChange={(e) => handleOptionSelected(e, "assignedCategory")}
                                options={policyCategoryOptions}
                                placeholder={translate(lang, "placeholderChoose")}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        height: "100%",
                                        border: 0,
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                        "&:hover": {
                                            borderColor: "transparent",
                                            boxShadow: "none",
                                        },
                                        backgroundColor: "transparent",
                                    }),
                                    valueContainer: (base, state) => ({
                                        ...base,
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        borderColor: "transparent",
                                    })
                                }}
                            />
                            {error.assignedCategory !== "" ? <small className="text-danger">{error.assignedCategory}</small> : <></>}
                        </div>
                        {/* <div className="card shadow">
                            <div className="card-header d-flex justify-content-between">
                                <h3>References</h3>
                                <div>
                                    <button className="btn btn-sm btn-primary">Add Reference</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-group py-2">
                                    <label className="form-label">{translate(lang, 'description')}</label>
                                    <input type="text" name="key" value={data.description} onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    <label className="error">{error.description}</label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="card-footer">
                        <div className="d-flex flex-row">
                            <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                            <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/settings/rider')}>{translate(lang, "back")}</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 px-2">
                    <div className="card shadow">
                        <div className="card-header d-flex justify-content-between">
                            <h3 className="m-0">
                                {translate(lang, "references")}
                            </h3>
                            {type === 'view' ? <></> :
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => addNewReference()}>{translate(lang, "addNewReferences")}</button>
                            }
                        </div>
                        <div className="card-body d-flex flex-column h-25-vh reference-container-listview">
                            {data.references && data.references.length > 0 ?
                                data.references.map((ref, i) => (
                                    <div>
                                        <div className="card card-body shadow h-auto">
                                            <div className="d-flex flex-column flex-md-row flex-wrap">
                                                <div className="col-12 mb-3">
                                                    <label className="form-label">{translate(lang, 'referenceType')}</label>
                                                    <Select
                                                        className="form-control col-12"
                                                        value={referenceOption.find(r => r.value === ref.type)}
                                                        isDisabled={type === 'view' ? true : false}
                                                        onChange={(e) => handleOptionSelected(e, "referenceType", i)}
                                                        options={referenceOption}
                                                        placeholder={translate(lang, "placeholderChoose")}
                                                        styles={{
                                                            control: (base, state) => ({
                                                                ...base,
                                                                height: "100%",
                                                                border: 0,
                                                                borderColor: "transparent",
                                                                boxShadow: "none",
                                                                "&:hover": {
                                                                    borderColor: "transparent",
                                                                    boxShadow: "none",
                                                                },
                                                                backgroundColor: "transparent",
                                                            }),
                                                            valueContainer: (base, state) => ({
                                                                ...base,
                                                                paddingLeft: 0,
                                                                paddingRight: 0,
                                                                borderColor: "transparent",
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="d-flex flex-column col-12 mb-3">
                                                    <label className="form-label">{translate(lang, 'remark')}</label>
                                                    <input type="text" name="remark" value={ref.remark} onChange={(e) => onInputChange(e, i)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                </div>
                                                <div className="d-flex flex-column col-12 mb-3">
                                                    {ref.type === 'url' ? (<>
                                                        <label className="form-label">{translate(lang, 'url')}</label>
                                                        <input type="text" name="url" value={ref.url} onChange={(e) => onInputChange(e, i)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                    </>) : (<>
                                                        <div className="d-flex flex-column">
                                                            {type === 'view' ? <></> : <div className="col-12">
                                                                <label className="form-label">{translate(lang, 'image')}</label>
                                                                <input type="file" name="image" onChange={(e) => onFileChange(e, i)} className="form-control h-auto" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {/* <label className="text-hint small mt-1 px-2">{translate(lang, "referenceImageFileTypeHint")}</label> */}
                                                            </div>}
                                                            {type === 'create' ? <></> : <div className="mt-3">
                                                                <label className="form-label">{translate(lang, 'currentReferenceDocument')}</label>
                                                                <div className="col-12 card reference-container-listview h-25-vh">
                                                                    <div className="mt-2 p-3">
                                                                        {ref.url === "" ? (<>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            {/* <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p>
                                                                            <p>{translate(lang, "noReferenceFileUploaded")}</p> */}
                                                                        </>) : (<>
                                                                            <img src={`${api.riderReferenceURL}/${ref.url}`}></img>
                                                                        </>)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </div>

                                                    </>)
                                                    }
                                                </div>
                                            </div>
                                            {type === 'view' ? <></> :
                                                <div className="card-footer d-flex justify-content-end pb-0">
                                                    <button className="btn btn-sm btn-danger" onClick={() => removeReference(i)}>{translate(lang, "delete")}</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                                : <p>{translate(lang, "noReferenceFound")}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};

export default connect(mapStateToProps)(RiderForm);