/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useRef, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
/// Link
import { Link, useHistory } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { companyName } from "../../../utils/info";
import { translate } from "../../../utils/translate";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const lang = props.lang;
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    getMenuToggle,
    openMenuToggle
  } = useContext(ThemeContext);
  const history = useHistory();
  const [path, setPath] = useState("");
  const [pathHeader, setPathHeader] = useState("");
  const [toggle, setToggle] = useState(getMenuToggle())
  const boxRef = useRef(null);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var rightContainer = document.querySelector(".header-right-container");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
    rightContainer.addEventListener("click", () => toggleMenuFunc(true));
  }, []);
  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      return aaa.classList.remove("menu-toggle");
      // return aaa.classList.toggle("menu-toggle");
    } else {
      aaabackdrop.classList.remove('d-none');
      return aaa.classList.add("menu-toggle");
    }
  }
  let scrollPosition = useScrollPosition();
  /// Path
  // path = path.split("/");
  // path = path[path.length - 1];

  useEffect(() => {
    history.listen((location) => {
      setPathHeader(location.pathname.split('/')[1]);
      console.log(location.pathname.split('/')[1])
      // console.log(location.pathname, settings, 'path', settings.indexOf(location.pathname));
      setPath(location.pathname);
      toggleMenuFunc(true);
    });
    if (!path) {
      setPath(history.location.pathname);
    }
    if (!pathHeader) {
      setPathHeader(history.location.pathname.split('/')[1]);
    }
  }, [history]);

  useEffect(() => {
    // console.log("refresh", pathHeader)
  }, [pathHeader])
  /// Active menu
  let deshBoard = [
    "dashboard",
  ], member = [
    "member",
  ], agent = [
    "agent",
  ], settings = [
    "settings",
  ], company = [
    "company",
  ], questionair = [
    "questionair",
  ];
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
      ref={boxRef}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${path === "/" || path.match(/(dashboard)+/i) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/dashboard" >
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">{translate(lang, 'dashboard')}</span>
            </Link>
            {/* <ul >
              <li><Link className={`${path === "" ? "mm-active" : "dashboard"}`} to="/dashboard"> Dashboard Light</Link></li>
              <li>
                <Link className={`${path === "dashboard-dark" ? "mm-active" : ""}`} to="/dashboard-dark">
                  Dashboard Dark
                </Link>
              </li>
              <li><Link className={`${path === "guest-list" ? "mm-active" : ""}`} to="/guest-list">Guest</Link></li>
              <li><Link className={`${path === "guest-detail" ? "mm-active" : ""}`} to="/guest-detail">Guest Detail</Link></li>
              <li><Link className={`${path === "concierge" ? "mm-active" : ""}`} to="/concierge">Concierge</Link></li>
              <li><Link className={`${path === "room-list" ? "mm-active" : ""}`} to="/room-list">Room</Link></li>
              <li><Link className={`${path === "reviews" ? "mm-active" : ""}`} to="/reviews">Reviews</Link></li>
              <li><Link className={`${path === "task" ? "mm-active" : ""}`} to="/task">Task</Link></li>
            </ul> */}
          </li>
          <li className={`${member.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" >
              <i className="flaticon-381-user-4"></i>
              <span className="nav-text">{translate(lang, 'member')}</span>
            </Link>
            <ul className={`mm-collapse ${member.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(member)+/i) ? "mm-active" : ""}`} to="/member">{translate(lang, 'list')}</Link></li>
              {/* <li><Link className={`${path === "/member-policy" ? "mm-active" : ""}`} to="/member-policy">{translate(lang, 'policy')}</Link></li> */}
            </ul>
          </li>
          <li className={`${agent.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user-2"></i>
              <span className="nav-text">{translate(lang, 'agent')}</span>
            </Link>
            <ul className={`mm-collapse ${agent.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(agent)+/i) ? "mm-active" : ""}`} to="/agent">{translate(lang, 'list')}</Link></li>
            </ul>
          </li>
          <li className={`${company.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-user"></i>
              <span className="nav-text">{translate(lang, 'company')}</span>
            </Link>
            <ul className={`mm-collapse ${company.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(company)+/i) ? "mm-active" : ""}`} to="/company">{translate(lang, 'list')}</Link></li>
            </ul>
          </li>
          {/* <li className={`${questionair.indexOf(pathHeader) > -1 ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-list-1"></i>
              <span className="nav-text">{translate(lang, 'questionnaire')}</span>
            </Link>
            <ul className={`mm-collapse ${questionair.indexOf(pathHeader) > -1 ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(questionair)+/i) ? "mm-active" : ""}`} to="/questionair">{translate(lang, 'list')}</Link></li>
            </ul>
          </li> */}
          {/* <li className={`${settings.includes(pathHeader) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="flaticon-381-settings-6"></i>
              <span className="nav-text">{translate(lang, 'settings')}</span>
            </Link>
            <ul className={`mm-collapse ${settings.includes(pathHeader) ? "mm-show" : ""}`} >
              <li><Link className={`${path.match(/(master-policy)+/i) ? "mm-active" : ""}`} to="/settings/master-policy">{translate(lang, 'masterPolicy')}</Link></li>
              <li><Link className={`${path.match(/(policy-category)+/i) ? "mm-active" : ""}`} to="/settings/policy-category">{translate(lang, 'policyCategory')}</Link></li>
              <li><Link className={`${path.match(/(rider)+/i) ? "mm-active" : ""}`} to="/settings/rider">{translate(lang, 'rider')}</Link></li>
            </ul>
          </li> */}
        </MM>
        <div className="copyright">
          <p><strong>{companyName}</strong> © 2023 All Rights Reserved</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  };
};

export default connect(mapStateToProps)(SideBar);
