import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getCompanyList, getMasterPolicyList, removeCompany, removeMasterPolicy } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { dummyData, promptMessage } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import CustomTable from "../../components/CustomTable";

const CompanyList = (props) => {
    const lang = props.lang;
    const tableColumns = [
        {
            Header: translate(lang, 'index'),
            accessor: (row, index) => {
                return index + 1;
            }
        },
        {
            Header: translate(lang, 'companyName'),
            accessor: (row, index) => {
                return row.companyName;
            }
        },
        {
            Header: translate(lang, 'companyCode'),
            accessor: (row, index) => {
                return row.term.toUpperCase();
            }
        },
        {
            Header: translate(lang, 'remark'),
            accessor: (row, index) => {
                return row.remark;
            }
        },
        {
            Header: translate(lang, 'companyLogo'),
            accessor: (row, index) => {
                return row.log ? api.companyLogo + "/" + row.logo : 'favicon.png';
                // return <><img src={`${api.companyLogo + "/" + row.logo}`} alt="icon" width="50px" height="50px"></img></>;
            },
            Cell: (props, index) => {
                return <div><img src={`${props.cell.value}`} alt="icon" width="20px" height="20px"></img></div>;
            }
        },
        {
            Header: translate(lang, 'action'),
            accessor: (row, index) => {
                return (<>
                    <Link
                        to={`/company/view/${row.term}`}
                        className="btn btn-primary shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-eye"></i>
                    </Link>
                    <Link
                        to={`/company/edit/${row.term}`}
                        className="btn btn-warning shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-pen"></i>
                    </Link>
                    {/* <Link
                        to={`/company/policy/create/${row.term}`}
                        className="btn btn-info shadow btn-xs sharp me-1"
                    >
                        <i className="fas fa-file-alt"></i>
                    </Link> */}
                    <span
                        onClick={() => removeItem(row.id)}
                        className="btn btn-danger shadow btn-xs sharp"
                    >
                        <i className="fa fa-trash"></i>
                    </span>
                </>)
            }
        },
    ]
    const [data, setData] = useState([]);
    const [fileData, setFileData] = useState({
        name: "company_list",
        excelData: [],
        hasCustom: false,
        customRows: [{
            customData: [],
            origin: -1, // last row or specified location like 'A1'
        }],
    });

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        setData([...dummyData('company')])
        setFileData([...dummyData('company')])
        // let query = {};
        // let res = await getCompanyList(query);
        // if (res?.status === 'success') {
        //     setData([...res.data])
        //     setFileData([...res.data])
        // } else {
        // }
    }

    function removeItem(id) {
        // Swal.fire({
        //     title: translate(props.lang, "deleteConfirmation"),
        //     text: translate(props.lang, "deleteMessage"),
        //     showDenyButton: true,
        //     showCancelButton: false,
        //     confirmButtonText: 'Yes',
        //     denyButtonText: 'No',
        //     customClass: {
        //         actions: 'my-actions',
        //         cancelButton: 'order-1 right-gap',
        //         confirmButton: 'order-2',
        //         denyButton: 'order-3',
        //     }
        // }).then(async (result) => {
        //     if (result.isConfirmed) {
        //         let res = await removeCompany(id)
        //         if (res.status === 'success') {
        //             promptMessage(lang, 'success', res.message)
        //             getList();
        //         } else {
        //             promptMessage(lang, 'error', res.message)
        //             getList();
        //         }
        //     }
        // })
    }

    return (
        <>
            <div className="card shadow">
                <div className="card-header">
                    <div>
                        <Link to="/company/create" className="btn btn-primary btn-sm">{translate(lang, 'createCompany')}</Link>
                    </div>
                </div>
                <div className="card-body">
                    <div id="job_data" className="dataTables_wrapper">
                        <CustomTable
                            lang={props.lang}
                            columns={tableColumns}
                            data={data}
                            excelExport={false}
                            fileData={fileData}
                        ></CustomTable>
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CompanyList);