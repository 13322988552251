import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { createMasterPolicy, createMemberPolicy, editMasterPolicy, editMemberPolicy, getCompanyList, getMasterPolicyList, getRiderList, getUserList, getUserPolicyList, removeMemberPolicy } from "../../../services/ApiService";
import { api } from "../../../utils/api";
import { promptMessage, relationshipOptions, selectDropdownStyle } from "../../../utils/functions";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import country from "../../../utils/country";
import Swal from "sweetalert2";

const MemberPolicyForm = (props) => {
    const history = useHistory();
    const lang = props.lang;
    const { type, slug } = useParams();
    const [userData, setUserData] = useState({
        fullName: "",
        gender: "",
        age: "",
        identityCard: 0,
        birthDate: "",
        email: "",
        phoneCode: "",
        phone: "",
        address: "",
    });
    const [agentList, setAgentList] = useState([]);
    const [currentPolicy, setCurrentPolicy] = useState("");
    const [policyList, setPolicyList] = useState([]);
    const [masterList, setMasterList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [policyData, setPolicyData] = useState({
        userId: type === 'create' ? slug : "",
        masterPolicyId: "",
        policyCategoryId: "",
        companyId: "",
        policyNumber: "",
        policyName: "",
        totalSumAssured: 0,
        premiumAssured: 0,
        premiumAmount: 0,
        assuredName: "",
        commencementDate: "",
        premiumPaymentTerm: 0,
        policyTerms: 0,
        expiryDate: "",
        policyTypeId: "",
        nominationList: [],
        rider: [],
        servicedBy: null,
    });
    // const [nominationList, setNominationList] = useState([
    //     // { name: "", identityCard: "", relationship: "", allocationPercentage: 0, phone: "", email: ""}
    // ])
    const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `${country[item].callingCode.length > 0 ? "+" + country[item].callingCode[0] : ""}` } }).filter((c) => c.value !== ""));
    const [relationOptions, setRelationOptions] = useState(relationshipOptions());
    const [riderOptions, setRiderOptions] = useState([]);
    const inputEl = useRef(null);
    const [file, setFile] = useState([]);
    const [error, setError] = useState({
        nominationList: []
    });

    useEffect(() => {
        // loadBasic();
        if (type === 'create') {
            setPolicyData({
                userId: type === 'create' ? slug : "",
                masterPolicyId: "",
                policyCategoryId: "",
                companyId: "",
                policyNumber: "",
                totalSumAssured: 0,
                premiumAssured: 0,
                assuredName: "",
                commencementDate: "",
                policyNumber: "",
                policyName: "",
                premiumAmount: 0,
                premiumPaymentTerm: 0,
                policyTerms: 0,
                expiryDate: "",
                policyTypeId: "",
                nominationList: [],
                rider: [],
                servicedBy: null,
            })
            // getUserDetails(slug);
        } else if (type === 'view') {
            if (!slug) {
                history.push('/member');
                return;
            }
            // getPolicyDetails(slug, true);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/member');
                return;
            }
            // getPolicyDetails(slug, true);
        }
    }, [])

    useEffect(() => {
        //get rider list
        if (policyData.policyCategoryId) {
            // getPolicyRiderList()
        } else {
            if (type === 'create') {
                setPolicyData({ ...policyData, rider: [] })
            }
        }

    }, [policyData.policyCategoryId])

    useEffect(() => {
        if (currentPolicy) {
            // getPolicyDetails(slug);
        }
    }, [currentPolicy])

    const loadBasic = async () => {
        let params = {};
        await getMasterPolicyList(params).then((response) => {
            let pcs = []
            const result = response.data.map(p => {
                pcs = [...pcs, ...p.policy_categories.map(c => ({ ...c, label: c.name, value: c.id }))];
                return { ...p, label: p.name, value: p.id }
            });
            setMasterList([...result]);
            setCategoryList([...pcs]);
        })
        let paramsCompany = {};
        await getCompanyList(paramsCompany).then((response) => {
            const result = response.data.map(p => { return { ...p, label: p.companyName, value: p.id } });
            setCompanyList([...result]);
        })
        getAgentList();
    }

    const getUserDetails = async (slug) => {
        let params = { id: slug };
        await getUserList(params).then((response) => {
            const result = response.data[0];
            setUserData({ ...result });
        }).catch((err) => {
            console.log(err);
            history.push('/member')
        })
    }

    const getAgentList = async () => {
        await getUserList({ isAgent: 1 }).then((response) => {
            const result = response.data.map(r => ({
                label: r.fullName,
                value: r.id,
            }));
            setAgentList([...result]);
        }).catch((err) => {
            console.log(err);
            history.push('/member')
        })
    }

    const getPolicyDetails = async (slug, basic = false) => {
        let params = { userId: slug };
        if (basic) { params.noJoin = 1; params.basic = 1 }
        else { params.id = currentPolicy }
        await getUserPolicyList(params).then((response) => {
            if (response.status === 'success') {
                const result = response.data[0];
                if (basic) {
                    setPolicyList([...response.data.map(dt => ({ ...dt, label: dt.policyNumber, value: dt.id }))]);
                    // setPolicyData({ ...result, rider: result.policy_riders, nominationList: [], rider: [] });
                    setUserData({ ...result.customer });
                    if (currentPolicy) setCurrentPolicy(currentPolicy)
                }
                if (!basic) {
                    // let tmp = result.find((policy) => policy.id === currentPolicy);
                    let policyCategory = result.policy_category.id;
                    let policyMaster = result.policy_category.policy_master.id;
                    let riders = result.user_policy_riders.map(r => ({ label: r.policy_rider.rider.name, value: r.policy_rider.rider.id, slug: r.policy_rider.rider.slug }))
                    let nominations = result.user_policy_nominations.map(n => ({ ...n, removed: false }));
                    [...Array(result.user_policy_nominations.length)].map(() => ({ fullName: "", identityCard: "", relationship: "", allocationPercentage: "", phoneCode: "", phone: "", email: "" }))
                    setPolicyData({ ...result, policyName: result.policyName ?? "", masterPolicyId: policyMaster, policyCategoryId: policyCategory, rider: [...riders], nominationList: nominations, })
                }
            } else {
                promptMessage(lang, 'error', response.message);
                history.push('/member');
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const getPolicyRiderList = async () => {
        if (type === 'create') {
            setPolicyData({ ...policyData, rider: [] })
        }
        let params = { policyCategoryId: policyData.policyCategoryId }
        let res = await getRiderList(params);
        if (res.status === 'success') {
            let data = res.data.map(r => ({ label: r.name, value: r.id, slug: r.slug }));
            setRiderOptions([...data]);
        } else {
            setRiderOptions([])
        }
    }

    const addNewNomination = () => {
        let temp = policyData;
        temp.nominationList.push({ fullName: "", identityCard: "", relationship: "", allocationPercentage: 0, phoneCode: "", phone: "", email: "" })
        let tempErr = error;
        tempErr.nominationList.push({ fullName: "", identityCard: "", relationship: "", allocationPercentage: "", phoneCode: "", phone: "", email: "" })
        setError({ ...tempErr });
        setPolicyData({ ...temp });
    }

    const removeNomination = (index) => {
        let temp = policyData;
        temp.nominationList[index].removed = true;
        let tempErr = error;
        tempErr.nominationList.splice(index, 1);
        setError({ ...tempErr });
        // temp.nominationList.splice(index, 1)
        setPolicyData({ ...temp });
    }

    const handleOptionSelected = (e, name, index = -1) => {
        if (type === 'view') return;
        let list = { ...policyData };
        if (name === 'masterPolicyId') {
            let master = masterList.find(m => m.value === e.value);
            master.policy_categories = master.policy_categories.map(c => { return { ...c, label: c.name, value: c.id } })
            setCategoryList([...master.policy_categories]);
            list["policyCategoryId"] = master.policy_categories.find(pc => pc.id === policyData.policyCategoryId) ? policyData.policyCategoryId : "";
            list[name] = e.value;
        }
        else if (name === 'rider') {
            if (type === 'edit') return;
            list.rider = e ?? [];
        }
        else if (index > -1) {
            list.nominationList[index][name] = e.value;
        }
        else {
            list[name] = e.value;
        }
        setPolicyData({ ...list });
    }

    const onInputChange = (event, index = -1) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = { ...policyData };
        if (index > -1) {
            temp.nominationList[index][name] = value;
        } else {
            temp[name] = value;
        }
        setPolicyData({ ...temp });
    };

    const onFileChange = (event) => {
        if (type === 'view') return;
        setFile(event.target.files);
    };

    const submitForm = async () => {
        let check = true;
        console.log(error.nominationList);
        promptMessage(lang, 'info', translate(lang, 'validateForm'), {
            timer: 1000,
            didOpen: () => {
                Swal.showLoading()
                let err = {
                    masterPolicyId: "",
                    policyCategoryId: "",
                    companyId: "",
                    policyNumber: "",
                    policyName: "",
                    totalSumAssured: "",
                    premiumAssured: "",
                    premiumAmount: '',
                    assuredName: "",
                    commencementDate: "",
                    premiumPaymentTerm: "",
                    policyTerms: "",
                    expiryDate: "",
                    servicedBy: "",
                    nominationList: [...error.nominationList],
                };
                [...Object.keys(err)].forEach((key) => {
                    if (key === 'nominationList') {
                        return;
                    }
                    if (type === 'edit' && ['pin', 'confirmPin'].includes(key)) {
                        // skip not showing data
                        return;
                    }
                    else if (policyData[key] === "" || policyData[key] === undefined || policyData[key] === null) {
                        check = false;
                        err[key] = translate(lang, "errorRequired")
                    }
                    if (type === 'create') {

                    }
                })
                let temp = policyData;
                let totalNominationPercentage = 0;
                let totalP = temp.nominationList.reduce((t, i) => t + parseFloat(i.allocationPercentage), totalNominationPercentage);
                if (totalP > 100) {
                    check = false;
                    promptMessage(lang, 'error', translate(lang, "nominationPercentageOverLimit"))
                }
                if (!currentPolicy && type !== 'create') {
                    check = false;
                    promptMessage(lang, 'error', translate(lang, 'noPolicySelected'))
                };
                //check nomination
                if (temp.nominationList.length > 0) {
                    temp.nominationList.forEach((nm, i) => {
                        [...Object.keys(nm)].forEach((key) => {
                            if (policyData.nominationList[i][key] === "" || policyData.nominationList[i][key] === undefined || policyData.nominationList[i][key] === null) {
                                check = false;
                                err.nominationList[i][key] = translate(lang, "errorRequired")
                            }
                        })
                    })
                }
                console.log(err);
                setError({ ...err });
                return check;
            },
            didDestroy: () => {
                Swal.hideLoading()
            },
        }).then(async (result) => {
            if (check) {
                if (type === 'create') {
                    await createMemberPolicy(policyData).then((res) => {
                        console.log(res.status)
                        if (res.status === 'success') {
                            promptMessage(lang, 'success', res.message)
                            history.push('/member');
                        } else {
                            promptMessage(lang, 'error', res.message)
                        }
                    }).catch((error) => {
                        promptMessage(lang, 'error', error);
                    })
                } else if (type === 'edit') {
                    let payload = {
                        userId: policyData.userId,
                        masterPolicyId: policyData.masterPolicyId,
                        policyCategoryId: policyData.policyCategoryId,
                        companyId: policyData.companyId,
                        policyNumber: policyData.policyNumber,
                        totalSumAssured: policyData.totalSumAssured,
                        premiumAssured: policyData.premiumAssured,
                        assuredName: policyData.assuredName,
                        commencementDate: policyData.commencementDate,
                        premiumAmount: policyData.premiumAmount,
                        premiumPaymentTerm: policyData.premiumPaymentTerm,
                        policyTerms: policyData.policyTerms,
                        expiryDate: policyData.expiryDate,
                        policyTypeId: policyData.policyTypeId,
                        nominationList: policyData.nominationList,
                        rider: policyData.rider,
                        servicedBy: policyData.servicedBy,
                    }
                    await editMemberPolicy(payload, policyData.id).then((res) => {
                        if (res.status === 'success') {
                            promptMessage(lang, 'success', res.message)
                            // history.push('/member');
                            getPolicyDetails(slug);
                        } else {
                            promptMessage(lang, 'error', res.message)
                        }
                    }).catch((error) => {
                        promptMessage(lang, 'error', error);
                    })
                }
            } else {
                promptMessage(lang, 'error', translate(lang, 'formErrorPleaseCheck'));
            }
        })
    }

    const removePolicy = async () => {
        if (!currentPolicy) {
            promptMessage(lang, 'error', translate(lang, 'noPolicySelected'))
            return;
        };
        Swal.fire({
            title: translate(props.lang, "deleteConfirmation"),
            text: translate(props.lang, "deleteMessage"),
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            customClass: {
                actions: 'my-actions',
                cancelButton: 'order-1 right-gap',
                confirmButton: 'order-2',
                denyButton: 'order-3',
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await removeMemberPolicy(currentPolicy);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message)
                    getPolicyDetails(slug);
                    setPolicyData({
                        userId: type === 'create' ? slug : "",
                        masterPolicyId: "",
                        policyCategoryId: "",
                        companyId: "",
                        policyNumber: "",
                        totalSumAssured: 0,
                        premiumAssured: 0,
                        assuredName: "",
                        commencementDate: "",
                        policyNumber: "",
                        policyName: "",
                        premiumAmount: 0,
                        premiumPaymentTerm: 0,
                        policyTerms: 0,
                        expiryDate: "",
                        policyTypeId: "",
                        nominationList: [],
                        rider: [],
                        servicedBy: null,
                    })
                } else {
                    promptMessage(lang, 'error', res.message)
                    getPolicyDetails(slug);
                }
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-column flex-md-row">
                <div className="col-12">
                    <div className="card shadow">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "detailsMemberPolicy" : type === "edit" ? "editMemberPolicy" : "createMemberPolicy"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column flex-md-column flex-xl-row gap-2">
                            <div className="col-12 col-xl-6 d-flex flex-column">
                                <div className="col-12 d-flex flex-row flex-wrap mb-1">
                                    {type === 'edit' ? <div className="form-group col-12 py-2">
                                        <label className="form-label">{translate(lang, 'memberPolicy')}</label>
                                        <Select
                                            className="form-control"
                                            value={policyList.filter(
                                                (option) => option.value === currentPolicy
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => setCurrentPolicy(e.id)}
                                            options={policyList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                    </div> : <></>}
                                    <div className="form-group col-12 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'fullName')}</label>
                                        <input type="text" value={userData.fullName} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2">
                                        <label className="form-label required">{translate(lang, 'masterPolicy')}</label>
                                        {type !== 'create' ? <input type="text" value={masterList.length > 0 && masterList.find(m => m.id === policyData.masterPolicyId) ? masterList.find(m => m.id === policyData.masterPolicyId).label : ""} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            : <Select
                                                className="form-control"
                                                value={masterList.filter(
                                                    (option) => option.value === policyData.masterPolicyId
                                                )}
                                                isDisabled={type === 'view' ? true : false}
                                                onChange={(e) => handleOptionSelected(e, "masterPolicyId")}
                                                options={masterList}
                                                placeholder={translate(lang, "placeholderChoose")}
                                                styles={{ ...selectDropdownStyle() }}
                                            />}
                                        {error.masterPolicyId == "" ? <></> : <p className="text-danger">{error.masterPolicyId}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyCategory')}</label>
                                        {type !== 'create' ? <input type="text" value={categoryList.length > 0 && categoryList.find(c => c.id === policyData.policyCategoryId) ? categoryList.find(c => c.id === policyData.policyCategoryId).label : ""} className="form-control" disabled placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                            : <Select
                                                className="form-control"
                                                value={categoryList.filter(
                                                    (option) => option.value === policyData.policyCategoryId
                                                )}
                                                isDisabled={type === 'view' ? true : false}
                                                onChange={(e) => handleOptionSelected(e, "policyCategoryId")}
                                                options={categoryList.filter(c => c.policyMasterId === policyData.masterPolicyId)}
                                                placeholder={translate(lang, "placeholderChoose")}
                                                styles={{ ...selectDropdownStyle() }}
                                            />}
                                        {error.policyCategoryId == "" ? <></> : <p className="text-danger">{error.policyCategoryId}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'company')}</label>
                                        <Select
                                            className="form-control"
                                            value={companyList.filter(
                                                (option) => option.value === policyData.companyId
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "companyId")}
                                            options={companyList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                        {error.companyId == "" ? <></> : <p className="text-danger">{error.companyId}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyNumber')}</label>
                                        <input type="text" value={policyData.policyNumber} name="policyNumber" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyNumber == "" ? <></> : <p className="text-danger">{error.policyNumber}</p>}
                                        {/* <Select
                                            className="form-control"
                                            value={categoryList.filter(
                                                (option) => option.value === policyData.policyCategoryId
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "policyCategoryId")}
                                            options={categoryList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    height: "100%",
                                                    border: 0,
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                    "&:hover": {
                                                        borderColor: "transparent",
                                                        boxShadow: "none",
                                                    },
                                                    backgroundColor: "transparent",
                                                }),
                                                valueContainer: (base, state) => ({
                                                    ...base,
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    borderColor: "transparent",
                                                })
                                            }}
                                        /> */}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyName')}</label>
                                        <input type="text" value={policyData.policyName} name="policyName" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyName == "" ? <></> : <p className="text-danger">{error.policyName}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'servicedBy')}</label>
                                        <Select
                                            className="form-control"
                                            value={agentList.filter(
                                                (option) => option.value === policyData.servicedBy
                                            )}
                                            isDisabled={type === 'view' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "servicedBy")}
                                            options={agentList}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{ ...selectDropdownStyle() }}
                                        />
                                        {error.servicedBy == "" ? <></> : <p className="text-danger">{error.servicedBy}</p>}
                                    </div>
                                    <div className="form-group col-12 py-2 px-2">
                                        <label className="form-label">{translate(lang, 'policyRider')}</label>
                                        <Select
                                            isMulti
                                            className="form-control"
                                            value={policyData.rider}
                                            isDisabled={type !== 'create' ? true : false}
                                            onChange={(e) => handleOptionSelected(e, "rider")}
                                            options={riderOptions}
                                            placeholder={translate(lang, "placeholderChoose")}
                                            styles={{
                                                ...selectDropdownStyle(),
                                                menu: (base, state) => ({
                                                    ...base,
                                                    width: "90%",
                                                })
                                            }}
                                        />
                                    </div>
                                    {/* <div className="py-2"> */}
                                    {/* <div hidden={type === 'view' ? true : false}>
                                        <label className="form-label">{translate(lang, 'icon')}</label>
                                        <input type="file" className="form-control h-100" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} onChange={(e) => onFileChange(e)} ref={inputEl} />
                                    </div> */}
                                    {/* {type !== 'create' ? (<div className="mt-2">
                                        <label className="form-label">{translate(lang, 'currentIcon')}</label>
                                        <div className={`icon-box`}>
                                            <img src={`${api.policyMasterIcon}/${data.icon}`} alt="logo" className="w-100 h-100" />
                                        </div>
                                    </div>) : <></>} */}
                                    {/* </div> */}
                                </div>
                                <hr></hr>
                                <div className="col-12 d-flex flex-row flex-wrap">
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'assuredName')}</label>
                                        <input type="text" value={policyData.assuredName} name="assuredName" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.assuredName == "" ? <></> : <p className="text-danger">{error.assuredName}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'totalSumAssured')}</label>
                                        <input type="number" value={policyData.totalSumAssured} name="totalSumAssured" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.totalSumAssured == "" ? <></> : <p className="text-danger">{error.totalSumAssured}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'premiumAmount')}</label>
                                        <input type="number" value={policyData.premiumAmount} name="premiumAmount" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.premiumAmount == "" ? <></> : <p className="text-danger">{error.premiumAmount}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'commencementDate')}</label>
                                        <input type="date" value={policyData.commencementDate} name="commencementDate" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.commencementDate == "" ? <></> : <p className="text-danger">{error.commencementDate}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'premiumPaymentTerm')}</label>
                                        <input type="number" value={policyData.premiumPaymentTerm} name="premiumPaymentTerm" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.premiumPaymentTerm == "" ? <></> : <p className="text-danger">{error.premiumPaymentTerm}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'policyTerms')}</label>
                                        <input type="number" value={policyData.policyTerms} name="policyTerms" onChange={(e) => onInputChange(e)} onWheel={(e) => e.target.blur()} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.policyTerms == "" ? <></> : <p className="text-danger">{error.policyTerms}</p>}
                                    </div>
                                    <div className="form-group col-12 col-md-6 py-2 px-2">
                                        <label className="form-label required">{translate(lang, 'expireDate')}</label>
                                        <input type="date" value={policyData.expiryDate} name="expiryDate" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        {error.expiryDate == "" ? <></> : <p className="text-danger">{error.expiryDate}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 d-flex flex-column flex-grow-1 nomination-container">
                                <div className="px-3 d-flex flex-row mb-3">
                                    <span className="h3 my-auto">{translate(lang, "nominationList")}</span>
                                    {['create', 'edit'].includes(type) ? <span className="ms-auto"><button className="btn btn-sm btn-primary" type="button" onClick={() => addNewNomination()}>Add Nomination</button></span> : <></>}
                                </div>
                                <div className="col-12 d-flex flex-row flex-wrap px-2">
                                    <div className="d-flex flex-column overflow-auto p-2 w-100 nomination-list-container">
                                        {policyData.nominationList && policyData.nominationList.length < 1 ? (<><p>{translate(lang, "noNominationAssigned")}</p></>) :
                                            policyData.nominationList.map((n, i) => n.removed ? <></> : (
                                                <div key={i} className="card shadow h-auto p-1 d-flex flex-column flex-wrap">
                                                    {['create', 'edit'].includes(type) ? <div className="card-header px-2 py-2 d-flex justify-content-end">
                                                        <button type="button" className="btn btn-sm btn-warning" onClick={() => removeNomination(i)}>Remove</button>
                                                    </div> : <></>}
                                                    <div className="card-body p-0">
                                                        <div className="d-flex flex-column flex-md-row flex-wrap">
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'fullName')}</label>
                                                                <input type="text" value={n.fullName} name="fullName" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList[i].fullName == "" ? <></> : <p className="text-danger">{error.nominationList[i].fullName}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'identityCard')}</label>
                                                                <input type="text" value={n.identityCard} name="identityCard" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList[i].identityCard == "" ? <></> : <p className="text-danger">{error.nominationList[i].identityCard}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'phoneCode')}</label>
                                                                <Select
                                                                    className="form-control"
                                                                    value={phoneCodeOptions.filter((option) => {
                                                                        return option.value === n.phoneCode
                                                                    }
                                                                    )}
                                                                    menuPlacement={i > 0 && i === policyData.nominationList.length - 1 ? "top" : "auto"}
                                                                    isDisabled={type === 'view' ? true : false}
                                                                    onChange={(e) => handleOptionSelected(e, "phoneCode", i)}
                                                                    options={phoneCodeOptions}
                                                                    placeholder={translate(lang, "placeholderChoose")}
                                                                    styles={selectDropdownStyle()}
                                                                />
                                                                {error.nominationList[i].phoneCode == "" ? <></> : <p className="text-danger">{error.nominationList[i].phoneCode}</p>}
                                                                {/* <input type="text" value={n.phoneCode} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'phone')}</label>
                                                                <input type="text" value={n.phone} name="phone" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList[i].phone == "" ? <></> : <p className="text-danger">{error.nominationList[i].phone}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'email')}</label>
                                                                <input type="text" value={n.email} name="email" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                                {error.nominationList[i].email == "" ? <></> : <p className="text-danger">{error.nominationList[i].email}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'relationship')}</label>
                                                                {/* <input type="text" value={n.relationship} name="relationship" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                                                <Select
                                                                    className="form-control"
                                                                    value={relationOptions.filter((option) => {
                                                                        return option.value === n.relationship
                                                                    })}
                                                                    menuPlacement={i > 0 && i === policyData.nominationList.length - 1 ? "top" : "auto"}
                                                                    isDisabled={type === 'view' ? true : false}
                                                                    onChange={(e) => handleOptionSelected(e, "relationship", i)}
                                                                    options={relationOptions}
                                                                    placeholder={translate(lang, "placeholderChoose")}
                                                                    styles={{
                                                                        ...selectDropdownStyle(),
                                                                        menu: (base, state) => ({
                                                                            ...base,
                                                                        }),
                                                                        menuList: (base, state) => ({
                                                                            ...base,
                                                                            maxHeight: "20vh"
                                                                        })
                                                                    }}
                                                                />
                                                                {error.nominationList[i].relationship == "" ? <></> : <p className="text-danger">{error.nominationList[i].relationship}</p>}
                                                            </div>
                                                            <div className="form-group col-12 col-md-6 py-2 px-2">
                                                                <label className="form-label">{translate(lang, 'allocationPercentage')}</label>
                                                                <input type="number" min={0} step={0.01} value={n.allocationPercentage} name="allocationPercentage" onChange={(e) => onInputChange(e, i)} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row gap-3">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm" onClick={() => history.push('/member')}>{translate(lang, "back")}</button>
                                <button type="button" hidden={type === 'edit' ? false : true} className="btn btn-danger btn-sm ms-auto" onClick={() => removePolicy()}>{translate(lang, "delete")}</button>
                                {/* <button type="button" hidden={type === 'create' ? true : false} className="btn btn-dark btn-sm ms-2 me-2" onClick={() => history.push('/member')}>{translate(lang, "delete")}</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-12 col-md-6 ps-2">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, "nominationList")}
                            </h3>
                        </div>
                        <div className="card-body col-12">
                            <div className="col-12 d-flex flex-row flex-wrap">
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'fullName')}</label>
                                    <input type="text" value={userData.fullName} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'identityCard')}</label>
                                    <input type="text" value={userData.ic} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'relationship')}</label>
                                    <input type="text" value={userData.relationship} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'allocationPercentage')}</label>
                                    <input type="text" value={userData.allocationPercentage} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'phoneCode')}</label>
                                    <input type="text" value={userData.phoneCode} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'phone')}</label>
                                    <input type="text" value={userData.phone} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                                <div className="form-group col-12 py-2 px-2">
                                    <label className="form-label">{translate(lang, 'email')}</label>
                                    <input type="text" value={userData.email} className="form-control" placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-dark btn-sm mx-2" onClick={() => history.push('/member')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(MemberPolicyForm);