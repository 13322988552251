import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { translate } from "../../../utils/translate";
import Select from 'react-select';
import { createAgent, createUser, editAgent, getUserList } from "../../../services/ApiService";
import country from "../../../utils/country";
import { promptMessage } from "../../../utils/functions";
import ListView from "../../components/ListView";


const CreateAgent = (props) => {
    const lang = props.lang;
    const { type, slug } = useParams();
    const [memberList, setMemberList] = useState([]);
    const [memberListFilter, setMemberListFilter] = useState([]);
    const [phoneCodeOptions, setPhoneCodeOptions] = useState(Object.keys(country).map((item, i) => { return { label: `(+${country[item].callingCode[0]}) ${country[item].name.common}`, value: `+${country[item].callingCode[0] ?? ""}` } }).filter((c) => c.value !== "" || c.value !== "+"));
    const history = useHistory();
    const [data, setData] = useState({
        fullName: "",
        email: "",
        username: "",
        phoneCode: "+60",
        phone: "",
        pin: "",
        confirmPin: "",
    });
    const [error, setError] = useState({
        fullName: "",
        email: "",
        username: "",
        phoneCode: "",
        phone: "",
        confirmPin: "",
    });
    // console.log(country);

    useEffect(() => {
        if (type === 'create') {
            setData({
                fullName: "",
                email: "",
                username: "",
                gender: "",
                phoneCode: "+60",
                phone: "",
                pin: "",
                confirmPin: "",
            })
        } else if (type === 'view') {
            if (!slug) {
                history.push('/agent');
                return;
            }
            getDetails(slug);
            getMemberList(slug);
        } else if (type === 'edit') {
            if (!slug) {
                history.push('/agent');
                return;
            }
            getDetails(slug);
            getMemberList(slug);
        }
    }, [])

    const listViewLayout = (list) => {
        return (
            <>
                <div className="card shadow d-flex flex-column agent-member-container-listview">
                    {list.map(i => <>
                        <div className="d-flex flex-column flex-wrap px-3 py-3">
                            <div className="d-flex flex-column flex-md-row flex-wrap py-1">
                                <div className=""><b>{translate(lang, "fullName")}</b>: {i.fullName}</div>
                                <div className="mx-2"><b>{translate(lang, "age")}</b>: {i.age}</div>
                                <div className="mx-2"><b>{translate(lang, "identityCard")}</b>: {i.identityCard && i.identityCard !== "" ? i.identityCard : "-"}</div>
                            </div>
                            <div className="d-flex flex-column flex-md-row flex-wrap">
                                <div className=""><b>{translate(lang, "phone")}</b>: {i.phoneCode} {i.phone}</div>
                                <div className="mx-2"><b>{translate(lang, "email")}</b>: {i.email && i.email !== "" ? i.email : "-"}</div>
                            </div>
                        </div>
                    </>)}
                </div>
            </>
        )
    }

    const onFilterInputChange = (input) => {
        // console.log('form', input);
        let temp;
        if (input !== "") {
            temp = memberList.filter(a => {
                let keys = Object.keys(a);
                let regex = new RegExp(input, "");
                if (keys.find(k => a[k] && a[k].toString().match(regex) && a[k].toString().match(regex).length > 0)) {
                    return a;
                }
                // console.log(a, keys);
                // keys.forEach(k => console.log(a[k], input))
                // return keys.forEach(k => {
                //     if (a[k]) {
                //         console.log(a[k].toString(), input.toString())
                //         if (a[k].toString().match(regex)) {
                //             return a
                //         }
                //     } else {
                //             console.log('no and skip next')
                //     }
                // })
                // return keys.forEach(k => a[k] && a[k].indexOf(input) > -1 ? a : false)
                // return passed ? a : false
            })
        } else {
            temp = memberList;
        }
        setMemberListFilter([...temp])
    }

    const getDetails = async (slug) => {
        let params = { id: slug };
        let res = await getUserList(params);
        if (res.status === 'success') {
            let data = res.data[0];
            setData({ ...data });
        } else {
            history.push('/agent');
        }
    }

    const getMemberList = async (slug) => {
        let params = { assignedAgent: slug, basic: 1 };
        let res = await getUserList(params);
        if (res.status === 'success') {
            let data = res.data;
            setMemberList([...data]);
            setMemberListFilter([...data]);
        } else {
            console.log(res);
        }
    }

    const onInputChange = (event) => {
        if (type === 'view') return;
        const { value, name } = event.target;
        let temp = data;
        temp[name] = value;
        setData({ ...temp });
    };

    const handleOptionSelected = (e, name) => {
        const { value } = e;
        const list = { ...data };
        list[name] = value;
        setData(list);
    }

    const submitForm = async () => {
        let check = true;
        let error = { fullName: "", phone: "", email: "", gender: "", pin: "", confirmPin: "", }
        if (!data.fullName) {
            check = false;
            error.fullName = translate(lang, "errorRequired");
        }
        if (!data.phoneCode) {
            check = false;
            error.phoneCode = translate(lang, "errorRequired");
        }
        if (!data.phone) {
            check = false;
            error.phone = translate(lang, "errorRequired");
        }
        if (data.phone.match(/\D+/)) {
            check = false;
            error.phone = translate(lang, "phoneInvalidFormat");
        }
        if (!data.email) {
            check = false;
            error.email = translate(lang, "errorRequired");
        }
        if (!data.email.match(/(\w+)@(\w+).(\w+)/gi)) {
            check = false;
            error.email = translate(lang, "emailInvalidFormat");
        }
        if (type === 'create') {
            if (!data.pin) {
                check = false;
                error.pin = translate(lang, "errorRequired");
            }
            if (!data.confirmPin) {
                check = false;
                error.confirmPin = translate(lang, "errorRequired");
            }
            if (data.pin && data.confirmPin) {
                if (data.pin !== data.confirmPin) {
                    check = false;
                    error.pin = translate(lang, "pinNotMatch")
                    error.confirmPin = translate(lang, "pinNotMatch")
                }
                if (data.pin.length < 6 || data.confirmPin.length < 6) {
                    check = false;
                    error.pin = translate(lang, "pinLengthTooShort")
                    error.confirmPin = translate(lang, "pinLengthTooShort")
                }
                if (data.pin.match(/\D+/) || data.confirmPin.match(/\D+/)) {
                    check = false;
                    error.pin = translate(lang, "pinOnlyNumber")
                    error.confirmPin = translate(lang, "pinOnlyNumber")
                }
            }
        }
        setError({ ...error });
        if (check) {
            if (type === 'create') {
                let res = await createAgent(data);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    resetForm();
                    history.push('/agent')
                } else {
                    promptMessage(lang, 'error', res.message);
                }
            } else if (type === 'edit') {
                let res = await editAgent(slug, data);
                if (res.status === 'success') {
                    promptMessage(lang, 'success', res.message);
                    resetForm();
                    history.push('/agent')
                } else {
                    promptMessage(lang, 'error', res.message);
                }
            }
        }
    }

    const resetForm = () => {
        setData({
            fullName: "",
            email: "",
            username: "",
            phoneCode: "",
            phone: "",
            pin: "",
            confirmPin: "",
        })
    }


    return (
        <>
            <div className="d-flex flex-column flex-md-row ">
                <div className="col-12 col-md-6">
                    <div className="card shadow">
                        <div className="card-header">
                            <h3 className="m-0">
                                {translate(lang, (type === "view" ? "detailsAgent" : type === "edit" ? "editAgent" : "createAgent"))}
                            </h3>
                        </div>
                        <div className="card-body col-12 d-flex flex-column">
                            <div className="form-group py-2">
                                <label className="form-label required">{translate(lang, 'fullName')}</label>
                                <input type="text" value={data.fullName} onChange={(e) => onInputChange(e)} name="fullName" className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                <small className="text-danger">{error.fullName}</small>
                            </div>
                            <div className="d-flex flex-column flex-md-row">
                                <div className="form-group py-2 col-12 col-md-7">
                                    <label className="form-label required">{translate(lang, 'phoneCode')}</label>
                                    {/* <input type="text" value={data.phone} onChange={(e) => { setData({ ...data, phone: e.target.value }) }} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} /> */}
                                    <Select
                                        className="form-control"
                                        value={phoneCodeOptions.filter((option) => {
                                            return option.value === data.phoneCode
                                        }
                                        )}
                                        isDisabled={type === 'view' ? true : false}
                                        onChange={(e) => handleOptionSelected(e, "phoneCode")}
                                        options={phoneCodeOptions}
                                        placeholder={translate(lang, "placeholderChoose")}
                                        styles={{
                                            control: (base, state) => ({
                                                ...base,
                                                height: "100%",
                                                border: 0,
                                                borderColor: "transparent",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    borderColor: "transparent",
                                                    boxShadow: "none",
                                                },
                                                backgroundColor: "transparent",
                                            }),
                                            valueContainer: (base, state) => ({
                                                ...base,
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                borderColor: "transparent",
                                            })
                                        }}
                                    />
                                    <small className="text-danger">{error.phoneCode}</small>
                                </div>
                                <div className="form-group py-2 col-12 col-md-5 ps-2">
                                    <label className="form-label required">{translate(lang, 'phone')}</label>
                                    <input type="tel" value={data.phone} name="phone" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                    <small className="text-danger">{error.phone}</small>
                                </div>
                            </div>
                            <div className="form-group py-2">
                                <label className="form-label required">{translate(lang, 'email')}</label>
                                <input type="email" value={data.email} name="email" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                <small className="text-danger">{error.email}</small>
                            </div>
                            {type === 'create' ?
                                <>
                                    <div className="form-group py-2">
                                        <label className="form-label required">{translate(lang, 'pin')}</label>
                                        <input type="password" minLength={6} maxLength={6} accept="numeric" value={data.pin} name="pin" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        <small className="text-danger">{error.pin}</small>
                                    </div>
                                    <div className="form-group py-2">
                                        <label className="form-label required">{translate(lang, 'confirmPin')}</label>
                                        <input type="password" minLength={6} maxLength={6} accept="numeric" value={data.confirmPin} name="confirmPin" onChange={(e) => onInputChange(e)} className="form-control" disabled={type === 'view' ? true : false} placeholder={type === 'view' ? "" : translate(lang, 'placeholderEnterHere')} />
                                        <small className="text-danger">{error.confirmPin}</small>
                                    </div>
                                </>
                                : <></>
                            }
                        </div>
                        <div className="card-footer">
                            <div className="d-flex flex-row">
                                <button type="button" hidden={type === 'view' ? true : false} className="btn btn-primary btn-sm" onClick={() => submitForm()}>{translate(lang, `${type === 'edit' ? 'save' : type}`)}</button>
                                <button type="button" className="btn btn-dark mx-2 btn-sm" onClick={() => history.push('/agent')}>{translate(lang, "back")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {type === "view" ?
                    (<div className="col-12 col-md-6 ps-3">
                        <div className="card shadow">
                            <div className="card-header">
                                <h3 className="m-0">
                                    {translate(lang, "assignedMemberList")}
                                </h3>
                            </div>
                            <div className="card-body col-12 d-flex flex-column h-25-vh">
                                {memberList.length > 0 ?
                                    <ListView data={memberListFilter} onFilterInputChange={(input) => onFilterInputChange(input)} layout={listViewLayout(memberListFilter)} search={true} lang={props.lang}></ListView>
                                    : <p>{translate(lang, "noMemberAssignedToThisAgent")}</p>
                                }
                            </div>
                        </div>
                    </div>)
                    : <></>
                }
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
};


export default connect(mapStateToProps)(CreateAgent);